import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const Plus = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M8.75 7.25H14.5V8.75H8.75V14.5H7.25V8.75H1.5V7.25H7.25V1.5H8.75V7.25Z" />
    </SvgIcon>
  )
}

export default Plus
